/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charge } from '../models/Charge';
import type { CreditCard } from '../models/CreditCard';
import type { Customer } from '../models/Customer';
import type { Invoice } from '../models/Invoice';
import type { StripePrice } from '../models/StripePrice';
import type { Subscription } from '../models/Subscription';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaymentsService {
    /**
     * Get Stripe Config
     * Returns a publishable key
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getPaymentsConfig(
        authorization: string,
    ): CancelablePromise<{
        /**
         * publishable key
         */
        key: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/config',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Create a subscription
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Subscription created
     * @throws ApiError
     */
    public static postPaymentsSubscription(
        authorization: string,
        requestBody?: {
            /**
             * Customer ID
             */
            customer_id: string;
            /**
             * Price Id
             */
            price_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/subscription',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * Update a subscription
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Subscription created
     * @throws ApiError
     */
    public static putPaymentsSubscription(
        authorization: string,
        requestBody?: {
            /**
             * Subscription Id
             */
            subscription_id: string;
            /**
             * Price Id
             */
            new_price_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/payments/subscription',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * Gets user subscriptions
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param customerId Customer Id
     * @returns Subscription Successful operation
     * @throws ApiError
     */
    public static getPaymentsSubscription(
        authorization: string,
        customerId: string,
    ): CancelablePromise<Array<Subscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/subscription',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'customer_id': customerId,
            },
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * Cancel user subscription
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Cancelled subscription
     * @throws ApiError
     */
    public static deletePaymentsSubscription(
        authorization: string,
        requestBody?: {
            /**
             * Subscription ID
             */
            subscription_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/payments/subscription',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns Customer Successful operation
     * @throws ApiError
     */
    public static createCustomer(
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/customer',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param customerId Customer Id
     * @returns Invoice Successful operation
     * @throws ApiError
     */
    public static getPaymentsUpcomingInvoice(
        authorization: string,
        customerId: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/upcoming-invoice',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'customer_id': customerId,
            },
        });
    }
    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param customerId Customer Id
     * @returns CreditCard Successful operation
     * @throws ApiError
     */
    public static getPaymentsPaymentMethods(
        authorization: string,
        customerId: string,
    ): CancelablePromise<Array<CreditCard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/payment-methods',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'customer_id': customerId,
            },
        });
    }
    /**
     * Remove payment method
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Removed payment method
     * @throws ApiError
     */
    public static deletePaymentsPaymentMethods(
        authorization: string,
        requestBody?: {
            /**
             * Payment method ID
             */
            payment_method_id: string;
            /**
             * Customer ID
             */
            customer_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/payments/payment-methods',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * Add payment method
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Removed payment method
     * @throws ApiError
     */
    public static postPaymentsPaymentMethods(
        authorization: string,
        requestBody?: {
            /**
             * Payment method ID
             */
            payment_method_id: string;
            /**
             * Customer ID
             */
            customer_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/payment-methods',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * Make default payment method
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Default payment method
     * @throws ApiError
     */
    public static postPaymentsPaymentMethodsDefault(
        authorization: string,
        requestBody?: {
            /**
             * Payment method ID
             */
            payment_method_id: string;
            /**
             * Customer ID
             */
            customer_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/payment-methods/default',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Error occurred`,
            },
        });
    }
    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns StripePrice Successful operation
     * @throws ApiError
     */
    public static getPaymentsPrices(
        authorization: string,
    ): CancelablePromise<Array<StripePrice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/prices',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param customerId Customer Id
     * @returns Charge Successful operation
     * @throws ApiError
     */
    public static getPaymentsCharges(
        authorization: string,
        customerId: string,
    ): CancelablePromise<Array<Charge>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payments/charges',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'customer_id': customerId,
            },
        });
    }
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartConfig } from '../models/ChartConfig';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChartsService {
    /**
     * List charts of organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @returns ChartConfig List of features of the organization
     * @throws ApiError
     */
    public static getCharts(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<ChartConfig>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/charts',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                400: `Bad Request`,
                404: `Organization not found`,
            },
        });
    }
    /**
     * Remove charts from an organization
     * Removes specified charts from an organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody List of features to remove
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @returns any Charts removed successfully
     * @throws ApiError
     */
    public static removeCharts(
        authorization: string,
        requestBody: Array<string>,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/charts',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Add charts to an organization
     * This endpoint allows you to add charts to a specified organization.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Charts to add to the organization
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @returns any Charts added successfully.
     * @throws ApiError
     */
    public static addCharts(
        authorization: string,
        requestBody: Array<ChartConfig>,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/charts',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
            },
        });
    }
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Flag } from '../models/Flag';
import type { OTA } from '../models/OTA';
import type { ResponseMessage } from '../models/ResponseMessage';
import type { Rollout } from '../models/Rollout';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RolloutService {
    /**
     * Initialize rollout
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static postRolloutOta(
        authorization: string,
        requestBody: {
            ota: OTA;
            name: string;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        message: string;
        rollout_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rollout-ota',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Initialize rollout
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns any Rollout Id
     * @throws ApiError
     */
    public static postRolloutFeature(
        authorization: string,
        requestBody: {
            feature: Flag;
            name: string;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        message: string;
        rollout_id: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rollout-feature',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get rollouts list
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns Rollout Rollout Id
     * @throws ApiError
     */
    public static listRollouts(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<Rollout>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rollout',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Get rollout
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns Rollout Rollout
     * @throws ApiError
     */
    public static getRollout(
        rolloutId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<Rollout> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rollout/{rollout_id}',
            path: {
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Delete rollout
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static deleteRollout(
        rolloutId: string,
        authorization?: string,
        orgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rollout/{rollout_id}',
            path: {
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Get plots
     * @param rolloutId Rollout Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns any Rollout Charts
     * @throws ApiError
     */
    public static getRolloutGetPlots(
        rolloutId: string,
        authorization?: string,
        orgId?: string,
    ): CancelablePromise<{
        charts_config: {
            bar_charts?: Record<string, any>;
            line_charts?: Record<string, any>;
        };
        latest_data: Record<string, any>;
        timeline_data: Record<string, any>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rollout/{rollout_id}/get-plots',
            path: {
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
        });
    }
    /**
     * Start next rollout
     * @param targetPercentage Target Percentage
     * @param rolloutId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @returns ResponseMessage Rollout Id
     * @throws ApiError
     */
    public static getRolloutStartNext(
        targetPercentage: number,
        rolloutId: string,
        authorization: string,
        orgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rollout/{rollout_id}/start-next',
            path: {
                'rollout_id': rolloutId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'target_percentage': targetPercentage,
                'org_id': orgId,
            },
        });
    }
}

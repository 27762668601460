/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bucket } from '../models/Bucket';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Invite a new user
     * Invite a new user with the provided email
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any User invited successfully
     * @throws ApiError
     */
    public static inviteUser(
        authorization: string,
        requestBody: {
            email: string;
            org: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/invite',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Register a new user
     * Register a new user with the provided email, password, first name, and last name. Assigns the 'admin' role to the first user in the organization.
     * @param requestBody
     * @returns any User registered successfully
     * @throws ApiError
     */
    public static registerUser(
        requestBody: {
            email: string;
            first_name: string;
            last_name: string;
            phone_number?: string;
        },
    ): CancelablePromise<{
        message?: string;
        user?: User;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Login with temporary password
     * Login with temporary password.
     * @param requestBody
     * @returns any User login successfully
     * @throws ApiError
     */
    public static loginWithTemporaryPassword(
        requestBody: {
            email: string;
            temporary_password: string;
            new_password: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/login-with-temporary-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Get current authenticated user info
     * Get current authenticated user info
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @returns any Get current user data successfully
     * @throws ApiError
     */
    public static getUsersMe(
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<{
        bucket?: Bucket;
        user: User;
        super_admin: boolean;
        trial_seconds_left: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Log in a user
     * Authenticate a user with the provided email and password. Returns user's details, organizations, groups, and user attributes.
     * @param requestBody
     * @returns any User logged in successfully
     * @throws ApiError
     */
    public static loginUser(
        requestBody: {
            email: string;
            password: string;
        },
    ): CancelablePromise<{
        message?: string;
        /**
         * User token
         */
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Log in a user
     * Authenticate a user with the provided email and password. Returns user's details, organizations, groups, and user attributes.
     * @param requestBody
     * @returns any User logged in successfully
     * @throws ApiError
     */
    public static postUsersGoogleLogin(
        requestBody: {
            token: string;
        },
    ): CancelablePromise<{
        message?: string;
        /**
         * User token
         */
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/google-login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }
    /**
     * Log out a user
     * Logout a user by instructing the client to discard the current tokens. This endpoint does not directly invalidate the tokens.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Logout message
     * @throws ApiError
     */
    public static logoutUser(
        authorization: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/logout',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Confirm terms and services
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Message
     * @throws ApiError
     */
    public static confirmTerms(
        authorization: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/confirm-terms',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Send password reset instructions to the provided email address.
     * @param requestBody Email ID of the user for whom to initiate password recovery.
     * @returns any Password reset instructions sent to email
     * @throws ApiError
     */
    public static forgotPassword(
        requestBody: {
            /**
             * The email address of the user who forgot their password.
             */
            email: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error message if any issue occurs.`,
            },
        });
    }
    /**
     * Confirm new password.
     * @param requestBody Email ID of the user for whom to initiate password recovery.
     * @returns any Password reset instructions sent to email
     * @throws ApiError
     */
    public static confirmNewPassword(
        requestBody: {
            /**
             * The email address of the user who forgot their password.
             */
            email: string;
            /**
             * Confirmation code from the email.
             */
            confirmation_code?: string;
            /**
             * New password.
             */
            password?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/confirm-new-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error message if any issue occurs.`,
            },
        });
    }
    /**
     * Get user details
     * Get user list.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param id ID of the user
     * @returns User Successful
     * @throws ApiError
     */
    public static getUsersDetails(
        authorization: string,
        id?: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/details/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Delete user
     * Delete user.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param id ID of the organization
     * @returns any Update successful
     * @throws ApiError
     */
    public static deleteUsersDetails(
        authorization: string,
        id?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/details/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Update user rights
     * Promote a user to the 'admin' role in the organization. Only an admin user can perform this action.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the group
     * @param requestBody
     * @returns any Promotion successful
     * @throws ApiError
     */
    public static postUsersRights(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        requestBody?: {
            /**
             * Email of the user to be promoted
             */
            target_email: string;
            rights: Record<string, any>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/rights',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
                404: `User or organization not found`,
            },
        });
    }
    /**
     * List all users in an organization
     * List all users in an organization, with their role (admin or user). This endpoint can be accessed only by the admin of the organization.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId ID of the sub-org
     * @returns User List of users in the organization
     * @throws ApiError
     */
    public static listAllUsersInOrg(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/list',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Assign a user to a group
     * Assign a user to a group with the specified role (admin or user).
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the parent organization
     * @param requestBody
     * @returns any User assigned to the group
     * @throws ApiError
     */
    public static assignUserToSubOrg(
        authorization: string,
        orgId?: string,
        requestBody?: {
            target_email: string;
            role: string;
            sub_org_id: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Remove a user from a group
     * Remove a user from a group.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the parent organization
     * @param requestBody
     * @returns any User removed from the group
     * @throws ApiError
     */
    public static removeUserFromSubOrg(
        authorization: string,
        orgId?: string,
        requestBody?: {
            target_email: string;
            sub_org_id: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/group',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Make a user admin of a group
     * This endpoint allows an admin of an organization or a group to make a user an admin of the group.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId ID of the organization
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static makeUserSubGroupAdmin(
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
            sub_org_id: string;
        },
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/group/admin',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }
    /**
     * Remove a user admin of a group
     * This endpoint allows an admin of an organization or a group to remove user as admin of the group.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId ID of the organization
     * @returns any Successfully made the user an admin
     * @throws ApiError
     */
    public static removeUserSubGroupAdmin(
        authorization: string,
        requestBody: {
            /**
             * Email of the user to be made admin
             */
            target_email: string;
            sub_org_id: string;
        },
        orgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/group/admin',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Permission denied`,
            },
        });
    }
}
